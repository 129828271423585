var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scoped_cover_div"},[_c('div',{staticClass:"jobs_table sp2"},[_c('table',[_vm._m(0),_c('tr',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('td',{},[_c('a',{staticClass:"table_action_btn",attrs:{"title":"DELETE"}},[_vm._v("DELETE")]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.makeEditable),expression:"makeEditable"}],staticClass:"table_action_btn",attrs:{"title":"CANCEL"},on:{"click":function($event){_vm.makeEditable=false,_vm.cancelEditable=true}}},[_vm._v("CANCEL")]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.makeEditable),expression:"makeEditable"}],staticClass:"table_action_btn",attrs:{"title":"UPDATE"}},[_vm._v("UPDATE")]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.cancelEditable),expression:"cancelEditable"}],staticClass:"table_action_btn",attrs:{"title":"EDIT"},on:{"click":function($event){_vm.makeEditable=true,_vm.cancelEditable=false}}},[_vm._v("EDIT")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("P")]),_c('th',[_vm._v("T")]),_c('th',[_vm._v("A")]),_c('th',[_vm._v("E")]),_c('th',[_vm._v("O")]),_c('th',[_vm._v("Action")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"label_and_element_wrapper"},[_c('input',{attrs:{"type":"text","placeholder":"p","value":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"label_and_element_wrapper"},[_c('input',{attrs:{"type":"text","placeholder":"t","value":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"label_and_element_wrapper"},[_c('input',{attrs:{"type":"text","placeholder":"a","value":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"label_and_element_wrapper"},[_c('input',{attrs:{"type":"text","placeholder":"e","value":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"label_and_element_wrapper"},[_c('input',{attrs:{"type":"text","placeholder":"o","value":""}})])])
}]

export { render, staticRenderFns }